import { Link } from "gatsby";
import Page from "../components/page";
import React from "react";
import SEO from "../components/seo";

const NotFoundPage = () => 
  <Page>
    <SEO title='404: Niet gevonden' />
    <div className='container py-16'>
      <h1 className='title mb-4'>Pagina niet gevonden</h1>
      <p>Je bent op een pagina terecht gekomen die niet bestaat.</p>
      <Link
        className='block mt-8 italic text-secondary hover:text-blue-600 text-sm transition-color underline'
        to='/'
      >
        Ga terug naar de homepage
      </Link>
    </div>
  </Page>;


export default NotFoundPage;
